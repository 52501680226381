<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple" style="text-align: left; margin-left: 220px; margin-top: 10px;">
            <p style="color: #7e7e7e; margin-left: 160px; margin-bottom: 5px; font-size: 20px">客户: {{ queryId }} 城市配运单签收列表</p >
            <el-row>
              <el-col :span="21" >
                <el-form
                    label-width="80px"
                    :model="dataForms"
                    ref="dataForms"
                    :inline="true"
                    size="mini"
                    label-position="left"
                    style="height: 70%;"
                >
                  <el-form-item label="运单号" prop="orderNo" placeholder="请输入" style="width:260px">
                    <el-input
                        maxlength="50"
                        placeholder="请输入运单号"
                        v-model="dataForms.orderNo"
                        size="mini"
                        style="width: 180px; "
                        class="input-with-input"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="运输状态" prop="transStatus" placeholder="请输入" style="width:269px">
                    <el-select
                        v-model="dataForms.transStatus"
                        placeholder="请选择"
                        style="width: 90px"
                    >
                      <el-option label="请选择" value=""></el-option>
                      <el-option label="已送达" value="8"></el-option>
                      <el-option label="已签收" value="10"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-col :span="3"  style="float: right; width:369px">
                    <el-button
                        size="mini"
                        v-if="waybillSearchFlag"
                        type="primary"
                        @click="handleClose"
                        :loading="loading"
                    >{{ loading ? "提交中 ..." : "查 询" }}</el-button
                    >
                    <el-button size="mini" type="danger" @click="resetFrom"
                    >重置</el-button
                    >
                  </el-col>
                  <el-form-item label="送达时间" prop="deliveryTimeBegin" style="width:289px">
                    <el-date-picker
                        v-model="value1"
                        style="width: 200px"
                        type="daterange"
                        size="mini"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        @change="timeChange"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="签收时间" prop="signTimeBegin" style="width:289px">
                    <el-date-picker
                        v-model="value2"
                        style="width: 200px"
                        type="daterange"
                        size="mini"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        @change="timeChange"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>

                </el-form>
              </el-col>
<!--              <el-col :span="3" >-->
<!--                <el-button-->
<!--                    size="mini"-->
<!--                    v-if="waybillSearchFlag"-->
<!--                    type="primary"-->
<!--                    @click="handleClose"-->
<!--                    :loading="loading"-->
<!--                >{{ loading ? "提交中 ..." : "查 询" }}</el-button-->
<!--                >-->
<!--                <el-button size="mini" type="danger" @click="resetFrom"-->
<!--                >重置</el-button-->
<!--                >-->
<!--              </el-col>-->
            </el-row>
          </div>
        </el-col>
        <!-- <el-col :span="5" style="text-align: center">
          <div class="grid-content bg-purple" style="float: right; margin: 10px; ">
            <el-input
                maxlength="50"
                placeholder="请输入运单号"
                v-model="dataForms.orderNo"
                size="mini"
                style="width: 240px; "
                class="input-with-input"
            >
              <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="handleQuery"
              ></el-button>
            </el-input>
            <el-button
                type="primary"
                size="mini"
                icon="el-icon-zoom-in"
                style="float: right"
                @click="dialog = true"
            ></el-button>
          </div>
        </el-col> -->
        <el-drawer
            title="运单管理高级查询条件"
            :before-close="cancelForm"
            :visible.sync="dialog"
            :with-header="false"
            direction="rtl"
            custom-class="demo-drawer"
            ref="drawer"
        >
          <div class="demo-drawer__content">
            <el-row>
              <el-col>
                <div class="grid-content bg-purple">
                  <el-card class="box-card">
                    <div>
                      <el-form
                          label-width="100px"
                          :model="dataForms"
                          ref="dataForms"
                          size="mini"
                          label-position="left"
                          style="height: 80%"
                      >
                        <el-form-item label="运输状态" prop="transStatus" placeholder="请输入" style="width: 100%">
                          <el-select
                              v-model="dataForms.transStatus"
                              placeholder="请选择"
                              style="width: 100%"
                          >
                            <el-option label="请选择" value="0"></el-option>
                            <el-option label="已送达" value="8"></el-option>
                            <el-option label="已签收" value="10"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="送达时间" prop="deliveryTimeBegin">
                          <el-date-picker
                              v-model="value1"
                              style="width: 100%"
                              type="daterange"
                              size="mini"
                              value-format="yyyy-MM-dd"
                              range-separator="至"
                              @change="timeChange"
                              format="yyyy-MM-dd"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
                          </el-date-picker>
                        </el-form-item>
                      </el-form>
                      <div
                          slot="footer"
                          class="dialog-footer"
                          style="text-align: center"
                      >
                        <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                        <el-button
                            size="mini"
                            v-if="waybillSearchFlag"
                            type="primary"
                            @click="handleClose"
                            :loading="loading"
                        >{{ loading ? "提交中 ..." : "查 询" }}</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="cancelForm"
                        >关闭</el-button
                        >
                        <el-button size="mini" type="danger" @click="resetFrom"
                        >重置</el-button
                        >
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-drawer>
      </el-row>
    </div>
    <div class="tableMain" v-if="tableData && tableData.length>0">
      <ces-table
          size="mini"
          :isSelection="true"
          :isIndex="true"
          :isPagination="true"
          :isHandle="true"
          v-loading="loading"
          :tableData="tableData"
          :tableCols="tableCols"
          :pagination="pagination"
          @refresh="handleCurrentChange"
          @handleImgClick="handleImgClick"
          @handleProNoderClick="handleProNoderClick"
          ref="cesTable"
      >
      </ces-table>
    </div>

    <el-dialog
        :visible.sync="transportVisible"
        width="1200px"
        v-if="transportVisible"
    >
      <trans-port :orderNo="orderNo" :closeTransPort="closeTransPort"></trans-port>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="transportVisible = false"
        >关闭</el-button
        >
      </span>
    </el-dialog>
    <ele-import
        :append="append"
        :fields="fields"
        :filepath="filepath"
        :formatter="formatter"
        :requestFn="requestFn"
        :rules="rules"
        :tips="tips"
        :title="title"
        :visible.sync="visible"
        @close="handleCloseImport"
        @finish="handleFinishImport"
    />
  </div>
</template>

<script>
// 引入组件
import SearchForm from "../../components/common/form.vue"
import cesTable from "../../components/common/table.vue"
import Drawer from "../../components/common/drawer.vue"
import arrayQuery from "../../components/arrayQuery.js"
import axios from "axios"
import qs from "qs"
import "element-ui/lib/theme-chalk/index.css"
import flightTable from "../../components/common/flightTable.vue"
import CityLinkageReg from "../../components/common/cityLinkageReg.vue"
import BuyerListCityLinkage from "../../components/common/BuyerAddressInformation.vue"
import SellerListCityLinkage from "../../components/common/SellerAddressInformation.vue"
import TransPort from "../reportManagement/transport.vue"

export default {
  data () {

    const status = [
      { label: "初始", value: "0" },
      { label: "已确认", value: "2" },
      { label: "已撤单", value: "4" },
    ]
    const statusProps = { label: "label", value: "value" }
    return {
      value1:[],
      value2:[],
      regionLevel_options:[],
      title: "物料导入",
      tips: ["物料名称必填", "件毛体任意一项必须大于0"],
      fields: {
        materialName: "物料名称",
        pkgQty: "件数",
        weight: "毛重",
        volume: "体积",
      },
      append: {
        // 这里是附加数据，导入数据时要加上iseditor，让用户可以启动编辑
        iseditor: true,
      },
      formatter: {},
      rules: {
        materialName: { required: true, message: "物料名称必填" },
      },
      filepath: "https://docs.qq.com/sheet/DUUZEQmpLeW1tUnhu/物料导入模板.xlsx", // 导入模板的图片文件夹地址
      visible: false,
      editFlag: false,
      saveFlag: false,
      table: false,
      dialog: false,
      loading: false,
      dialogVisible_waybilladd: false,
      dialog_waybillMan_Title_Flag: "",
      transportVisible:false,
      orderNo:'',
      dialogVisible_orderNo: "",
      dialog_comm_carriers: false,
      dialogVisible_imgUrl: false,
      dialogImageUrl_via: "",
      saveAndAddFlag: false,
      dialog_waybillManagementReceiptUpload: false,
      hideUp: false,
      dialogImageUrl: "",
      dialogVisible_saveUpload: false,
      showBtnImg: true,
      noneBtnImg: false,
      dialog_waybillMan_JustShow_tit: "",
      dialogVisible_JustShow: false,
      dialogVisible_imgUrl_flag: false,
      limitCountImg: 1,
      carriers_form: {
        carriersId: "",
        carriersName: "",
      },
      tableDatadialog: [],
      materialTableData: [],
      materialTableData_JustShow: [],
      columns: [
        {
          id: "selection",
          type: "selection",
          label: "",
          fixed: "left",
          width: "55",
          prop: "",
          isShow: true,
          align: "center",
        },
        {
          id: "carriersId",
          type: "",
          label: "编码",
          fixed: false,
          width: "280",
          prop: "carriersId",
          isShow: true,
          align: "center",
        },
        {
          id: "carriersName",
          type: "",
          label: "名称",
          fixed: false,
          width: "280",
          prop: "carriersName",
          isShow: true,
          align: "center",
        },
      ],
      tableDatadialog_pagination: {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      },
      pagesizes: [5, 10, 15, 20],
      waybillDoFormrules: {
        consignId: [
          {
            required: true,
            message: "请选择发货方",
            trigger: ["blur", "change"],
          },
        ],
        consignName: [
          { required: true, message: "请输入发货方名称", trigger: "blur" },
        ],
        consignAddress: [
          { required: true, message: "发货方地址必填", trigger: "blur" },
        ],
        consignMobile: [
          { required: true, message: "发货方手机号必填", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: "^1[3456789]\\d{9}$",
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        vendeeName: [
          { required: true, message: "请输入收货方名称", trigger: "blur" },
        ],
        vendeeAddress: [
          { required: true, message: "收货方地址必填", trigger: "blur" },
        ],
        vendeeMobile: [
          { required: true, message: "收货方手机号必填", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: "^1[3456789]\\d{9}$",
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        },
      },
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      saveReceipt: {
        orderNo: "",
        orderReceiptUrl: "",
      },
      imgurl_s: "",
      imgflag: true,
      imgflagmodel: false,
      srcList: [],
      dialogImageUrl_via_srcList: [],
      imgflagdel: false,
      AccurateAndUnique_orderNo: "",
      dataForms: {
        orderNo:"",
        vendeeGroupId: "",
        transStatus: "",
        signTime: "",
        deliveryTime:"",
        deliveryTimeBegin: "",
        deliveryTimeEnd:"",
        signTimeBegin:"",
        signTimeEnd:""
      },
      waybillDoForm: {
        orderNo: "",
        consignId: "",
        consignName: "",
        consignAddress: "",
        consignLinkman: "",
        consignPhone: "",
        consignMobile: "",
        vendeeName: "",
        vendeeAddress: "",
        vendeeLinkman: "",
        vendeePhone: "",
        vendeeMobile: "",
        replyDeliveryTime: "",
        requiredDeliveryTime: "",
        pkgQty: "",
        weight: "",
        volume: "",
        wunit: "",
        vunit: "",
        vendeeLine: "",
        vendeeProvince: "",
        vendeeProvinceName: "",
        vendeeCity: "",
        vendeeCityName: "",
        vendeeDistrict: "",
        vendeeDistrictName: "",
        consignProvince: "",
        consignProvinceName: "",
        consignCity: "",
        consignCityName: "",
        consignDistrict: "",
        consignDistrictName: "",
        chintBusiness: "0",
        outGoing: "0",
      },
      formLabelWidth: "80px",
      timer: null,
      searchData: {
        // 查询表单的对应的值
        orderNo: "",
      },
      apiId: "null",
      fieldList: [],
      // 表格
      tableData: [], // 表格数据
      tableCols: [
        // 表格列数据
        // {label:'运单编号',prop:'orderNo',width:'160',align:'center'},
        {
          label: "运单号",
          type: "imgLinkProNoder",
          prop: "orderNo",
          align: "center",
          width: "230",
        },
        {
          label: "运输状态",
          prop: "transStatus",
          align: "center",
          width: "220",

          formatter: (row) => {
            if (row.transStatus == 8) {
              return "已送达"
            } else if (row.transStatus == 10) {
              return "已签收"
            } else {
              return ""
            }
          },
        },

        {
          label: "送达时间",
          prop: "deliveryTime",
          width: "220",
          align: "center",
          formatter: (row, column) => {
            if (row.deliveryTime
                == null) {
              return ""
            } else {
              return this.$moment(row.deliveryTime
              ).format(
                  "YYYY-MM-DD HH:mm:ss"
              )
            }
          },
        },
        {
          label: "签收时间",
          prop: "signTime",
          width: "220",
          align: "center",
          formatter: (row, column) => {
            if (row.signTime == null) {
              return ""
            } else {
              return this.$moment(row.signTime).format("YYYY-MM-DD HH:mm:ss")
            }
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: "220",
          align: "center",
          formatter: (row, column) => {
            if (row.createTime == null) {
              return ""
            } else {
              return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
            }
          },
        },


      ],
      tableHandles: [
        // 这是表格和表单之间的一个按钮，我的项目不需要
        { label: "新增", type: "primary", handle: (row) => "" },
      ],
      pagination: {
        // 分页数据
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      merge: [],
      queryId:'',
      waybillSearchFlag: true,
      waybillSearchFlagA: true,
      waybillModifyFlag: true,
      waybillManagementAddFlag: true,
      waybillReleaseFlag: true,
      waybillwaybillPublishFallbackFlag: true,
      waybillServiceFlag: true,
      waybillCancelFlag: true,
      waybillCollectFlag: true,
      waybillCollectBackFlag: true,
      waybillManagementReceiptUploadFlag: true,
      waybillreceiptConfirmationFlag: true,
      waybillExportFlag: true,
      consignmentFlag: true,
      handoverFlag: true,
      immovableFlag: true,
      downloadName: "",
      addList: {
        province: "",
        city: "",
        area: "",
      },
      BuyerList: {
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
      },
      SellerList: {
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        area: "",
        areaName: "",
      },
    }
  },

  components: {
    // 引入组件后注册组件
    cesTable,
    Drawer,
    flightTable,
    CityLinkageReg,
    BuyerListCityLinkage,
    SellerListCityLinkage,
    TransPort
  },
  computed: {
    getUploadUrl () {
      const filePath = `${this.$filePath}`
      return filePath
    },
  },
  mounted () {
    const query = this.$route.query
    this.queryId = query.vendeeGroupId
    if(!query.vendeeGroupId){
      this.$router.push({
        path:'500'
      })
    }
    console.log(query)
    // _this.regionLevel_options = [
    //   {
    //     value: '8',
    //     label: '已送达'
    //   }, {
    //     value: '10',
    //     label: '已签收'
    //   }
    // ]
    this.getTableAndForm()
    const parentId = "2_2_2"
    const currentPageButtons = arrayQuery.text(parentId)
    // console.log(currentPageButtons);
    const _this = this
    _this.currentPageButtons = currentPageButtons

  },
  methods: {
    timeChange(e){
      console.log(e)
    },
    // 获取数据
    getCity (data) {
      this.addList.province = data.province
      this.addList.city = data.city
      this.addList.area = data.area
    },
    getBuyerListCityLinkageCity (data) {
      this.BuyerList.province = data.province
      this.BuyerList.provinceName = data.provinceName
      this.BuyerList.city = data.city
      this.BuyerList.cityName = data.cityName
      this.BuyerList.area = data.area
      this.BuyerList.areaName = data.areaName
      this.waybillDoForm.vendeeProvince = data.province
      this.waybillDoForm.vendeeProvinceName = data.provinceName
      this.waybillDoForm.vendeeCity = data.city
      this.waybillDoForm.vendeeCityName = data.cityName
      this.waybillDoForm.vendeeDistrict = data.area
      this.waybillDoForm.vendeeDistrictName = data.areaName
    },
    getSellerListCityLinkageCity (data) {
      this.SellerList.province = data.province
      this.SellerList.provinceName = data.provinceName
      this.SellerList.city = data.city
      this.SellerList.cityName = data.cityName
      this.SellerList.area = data.area
      this.SellerList.areaName = data.areaName
      this.waybillDoForm.consignProvince = data.province
      this.waybillDoForm.consignProvinceName = data.provinceName
      this.waybillDoForm.consignCity = data.city
      this.waybillDoForm.consignCityName = data.cityName
      this.waybillDoForm.consignDistrict = data.area
      this.waybillDoForm.consignDistrictName = data.areaName
    },
    onInput0_100 (e) {
      this.$message.closeAll()
      // 验证是否是纯数字
      const isNumber = /^\d*$/.test(e.target.value)
      // 过滤非数字
      e.target.value = e.target.value.replace(/\D/g, "")
      if (!isNumber || e.target.value < 0 || e.target.value > 100000) {
        this.$message.warning("只能输入[0,100000]区间的整数")
      }
      e.target.value =
          (e.target.value >= 0 &&
              e.target.value <= 100000 &&
              e.target.value.match(/^\d*/g)[0]) ||
          null
    },
    onInput0_200 (e) {
      this.$message.closeAll()
      if (e.target.value < 0 || e.target.value > 100000) {
        this.$message.warning("只能输入[0,100000]区间的数")
      }
      e.target.value =
          (e.target.value >= 0 &&
              e.target.value <= 100000 &&
              e.target.value.match(/^\d{1,3}(\.\d*)?$/)[0]) ||
          null
    },
    handleClose_JustShow () {
      this.dialogVisible_JustShow = false
      this.materialTableData_JustShow = []
    },
    // ---------------------------------------回单副本--------------------------------
    handleUpSuccess (response, file, fileList) {
      this.dialogImageUrl = `${this.$imageiPPath}` + response.data
      this.saveReceipt.orderReceiptUrl = response.data
      this.imagesUp = response.data
      this.hideUp = fileList.length == 1
      this.$message.success(response.msg)
      this.imgflag = false
      this.imgflagmodel = true
      this.imgflagdel = true
      this.imgurl_s = `${this.$imageiPPath}` + response.data
      this.srcList.push(`${this.$imageiPPath}` + response.data)
    },
    handleUpError (err, file, fileList) {},
    // 删除图片
    handleUpRemove (file, fileList) {
      this.imagesUp = []
      this.hideUp = false
      this.saveReceipt.orderReceiptUrl = ""
      this.noneBtnImg = fileList.length >= this.limitCountImg
    },
    // 预览图片
    handleUpPreview (file) {
      this.dialogVisible_saveUpload = true
    },
    // 上传图片
    handleUpUpload (file, fileList) {
      this.hideUp = true
      this.noneBtnImg = fileList.length >= this.limitCountImg
    },
    delimg () {
      this.imgflag = true
      this.imgflagdel = false
      this.imgflagmodel = false
      this.saveReceipt.orderReceiptUrl = ""
    },
    materialNameTleBlur () {
      const tableData = this.materialTableData
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].materialName == "") {
          this.$message({
            message: "第" + (i + 1) + "条数据物料名称没有进行填写，请填写",
            type: "warning",
          })
          return false
        }
      }
    },
    materialSummation () {
      const tableData = this.materialTableData
      const volume = []
      const weight = []
      const pkgQty = []
      for (let i = 0; i < tableData.length; i++) {
        volume.push(tableData[i].volume)
        weight.push(tableData[i].weight)
        pkgQty.push(tableData[i].pkgQty)
      }
      this.waybillDoForm.volume = this.getFloat(eval(volume.join("+")), 4)
      this.waybillDoForm.weight = this.getFloat(eval(weight.join("+")), 4)
      this.waybillDoForm.pkgQty = eval(pkgQty.join("+"))
    },
    getFloat (number, n) {
      n = n ? parseInt(n) : 0
      if (n <= 0) {
        return Math.round(number)
      }
      number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n) // 四舍五入
      number = Number(number).toFixed(n) // 补足位数
      return number
    },
    edit (row, index) {
      const tableData = this.materialTableData
      for (let i = 0; i < tableData.length; i++) {
        tableData[i].iseditor = true
      }
      this.materialTableData = tableData
    },
    save (row, index) {
      const tableData = this.materialTableData
      for (let i = 0; i < tableData.length; i++) {
        tableData[i].iseditor = false
      }
      this.materialTableData = tableData
      this.materialSummation()
    },
    // 导入数据模块————————————————————————————————————————————————————————————————————————
    importExcelData () {
      this.visible = true
    },
    async requestFn (data) {
      this.materialTableData = data
      this.materialSummation()
      return Promise.resolve()
    },
    handleCloseImport () {
      // console.log('弹窗关闭了~')
    },
    handleFinishImport () {
      // console.log('导入完毕了~')
    },
    // 导入数据模块——————————————————————————————————————————————————————————————————————————
    addrow () {
      if (this.materialTableData.length == 0) {
        this.materialTableData.push({
          materialName: "",
          volume: 0.0,
          weight: 0.0,
          pkgQty: 0,
          iseditor: true,
        })
      } else {
        const addRowFlag = this.materialNameTleBlur()
        if (addRowFlag == false) {
        } else {
          this.materialTableData.push({
            materialName: "",
            volume: 0.0,
            weight: 0.0,
            pkgQty: 0,
            iseditor: true,
          })
        }
      }
    },
    // 获取承运商
    onInputFocus () {
      // 承运商弹出先暂时关掉不可用
      this.companySearch()
      this.dialog_comm_carriers = true
    },
    companySearch () {
      const data = new URLSearchParams()
      data.append("carriersId", this.carriers_form.carriersId)
      data.append("carriersName", this.carriers_form.carriersName)
      data.append("pageSize", this.tableDatadialog_pagination.pageSize)
      data.append("pageNo", this.tableDatadialog_pagination.pageNum)
      axios({
        method: "post",
        url: `${this.$apiPath}/api/urban/carriers/getBaCarriersAll`,
        data: data,
      }).then((res) => {
        this.tableDatadialog = res.data.items
        this.tableDatadialog_pagination.total = res.data.total
      })
    },
    companyOkay () {},
    // 此方法是点击分页时触发的查询，
    handleCurrentChange () {
      this.waybillManagementSearch()
    },
    closeTransPort(){
      this.transportVisible = false;
      this.waybillManagementSearch()
    },
    handleProNoderClick (orderNo) {
      this.transportVisible = true
      this.orderNo = orderNo.imgurl
      //   const orderNoData = new URLSearchParams()
      //   orderNoData.append("orderNo", orderNo.orderNo)
      // console.log(orderNo)
      // this.$router.push({
      //   path:'/transport',
      //   query:{
      //     orderNo:orderNo.imgurl
      //   }
      // })

    },
    handleImgClick (imgurl) {
      this.dialogImageUrl_via = `${this.$imageiPPath}` + imgurl.imgurl
      this.dialogImageUrl_via_srcList.push(
          `${this.$imageiPPath}` + imgurl.imgurl
      )
      this.dialogVisible_imgUrl_flag = false
      this.dialogVisible_imgUrl = true
    },
    handleCloseImgDialog () {
      this.dialogImageUrl_via = ""
      this.dialogVisible_imgUrl_flag = false
      this.dialogVisible_imgUrl = false
      this.dialogImageUrl_via_srcList.length = 0
    },
    // 获取表格和表单数据
    async getTableAndForm () {
      this.waybillManagementSearch()
    },
    handleQuery () {
      // 查询
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      const data = new URLSearchParams()
      data.append('transStatus', this.dataForms.transStatus);
      data.append("orderNo", this.dataForms.orderNo)
      data.append('vendeeGroupId', this.queryId)
      data.append("pageSize", this.pagination.pageSize)
      data.append("pageNo", this.pagination.pageNum)
      // console.log(data);
      axios({
        method: "post",
        // url: `${this.$apiPath}/api/urban/waybill/queryWaybill`,
        url: `${this.$apiPath}/api/urban/permit/ec/queryEcSignBills`,
        data: data,
      }).then((res) => {
        this.tableData = res.data.data.items
        this.pagination.total = res.data.data.total
        this.loading = false
      })

    },
    // 获取表格勾选数据
    selectionLineChangeHandle (val) {
      this.merge = this.$refs.cesTable.multipleSelection
    },
    handleClose_waybilladd () {
      this.dialogVisible_waybilladd = false
      this.editFlag = false
      this.saveFlag = false
      this.$refs.waybillDoForm.resetFields()
      this.materialTableData = []
      this.clearBuyerListData()
      this.clearSellerListData()
    },
    clearBuyerListData () {
      this.BuyerList.province = ""
      this.BuyerList.provinceName = ""
      this.BuyerList.city = ""
      this.BuyerList.cityName = ""
      this.BuyerList.area = ""
      this.BuyerList.areaName = ""
    },
    clearSellerListData () {
      this.SellerList.province = ""
      this.SellerList.provinceName = ""
      this.SellerList.city = ""
      this.SellerList.cityName = ""
      this.SellerList.area = ""
      this.SellerList.areaName = ""
    },
    // 订单查询精确
    waybillManagementSearch () {
      this.loading = true
      let deliveryTimeBegin;
      let deliveryTimeEnd;
      let signTimeEnd;
      let signTimeBegin;
      if(this.value1 == '' || this.value1 == null){
        deliveryTimeBegin = '';
        deliveryTimeEnd = '';
      }else {
        deliveryTimeBegin = this.value1[0];
        deliveryTimeEnd = this.value1[1];
      }
      if(this.value2 == '' || this.value2 == null){
        signTimeBegin = '';
        signTimeEnd = '';
      }else {
        signTimeBegin = this.value2[0];
        signTimeEnd = this.value2[1];
      }
      const data = new URLSearchParams();
      data.append('vendeeGroupId', this.queryId)
      data.append('transStatus', this.dataForms.transStatus);
      data.append('deliveryTimeBegin', deliveryTimeBegin);
      data.append('deliveryTimeEnd', deliveryTimeEnd);
      data.append('signTimeBegin', signTimeBegin);
      data.append('signTimeEnd', signTimeEnd);
      data.append('orderNo',this.dataForms.orderNo)
      data.append('pageSize', this.pagination.pageSize);
      data.append('pageNo', this.pagination.pageNum);
      axios({
        method: "post",
        url: `${this.$apiPath}/api/urban/permit/ec/queryEcSignBills`,
        data: data,
      }).then((res) => {
        console.log(res.data.data.total);
        if(res.data.data.total  === 0){
          this.$message({
            type: 'warning',
            message: '暂无数据'
          })
        } else {
          if(res.success === false){
            this.$message({
              type: 'error',
              message: res.msg
            })
            return
          }
        }

        this.tableData = res.data.data.items
        this.pagination.total = res.data.data.total
        this.loading = false
      })
    },
    waybillManagementSearch_Multiple () {
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      this.waybillManagementSearch()
    },
    // 新增订单
    waybillManagementAdd () {
      this.dialog_waybillMan_Title_Flag = "新建运单"
      this.saveAndAddFlag = true
      this.editFlag = false
      this.saveFlag = false
      this.dialogVisible_waybilladd = true
    },
    // 修改订单
    waybillManagementModify () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "修改操作必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "修改操作只能选择单条数据进行操作",
        })
      } else {
        if (this.merge[0].transStatus == 0) {
          this.dialog_waybillMan_Title_Flag = "修改运单"
          this.saveAndAddFlag = false
          this.editFlag = true
          this.saveFlag = true
          this.dialogVisible_waybilladd = true
          const orderNo = new URLSearchParams()
          orderNo.append("orderNo", this.merge[0].orderNo)
          axios({
            method: "post",
            url: `${this.$apiPath}/api/urban/waybill/getWaybill`,
            data: orderNo,
          }).then((res) => {
            res.data.data.waybillDo.volume = this.getFloat(
                res.data.data.waybillDo.volume,
                4
            )
            res.data.data.waybillDo.chintBusiness = JSON.stringify(
                res.data.data.waybillDo.chintBusiness
            )
            res.data.data.waybillDo.outGoing = JSON.stringify(
                res.data.data.waybillDo.outGoing
            )
            const processedData = this.nullToStr(res.data.data.waybillDo)
            this.BuyerList.province = processedData.vendeeProvince
            this.BuyerList.city = processedData.vendeeCity
            this.BuyerList.area = processedData.vendeeDistrict
            this.BuyerList.provinceName = processedData.vendeeProvinceName
            this.BuyerList.cityName = processedData.vendeeCityName
            this.BuyerList.areaName = processedData.vendeeDistrictName
            this.SellerList.province = processedData.consignProvince
            this.SellerList.city = processedData.consignCity
            this.SellerList.area = processedData.consignDistrict
            this.SellerList.provinceName = processedData.consignProvinceName
            this.SellerList.cityName = processedData.consignCityName
            this.SellerList.areaName = processedData.consignDistrictName
            this.waybillDoForm = processedData
            for (let i = 0; i < res.data.data.waybillItemDos.length; i++) {
              res.data.data.waybillItemDos[i].iseditor = false
              res.data.data.waybillItemDos[i].volume = this.getFloat(
                  res.data.data.waybillItemDos[i].volume,
                  4
              )
            }
            this.materialTableData = res.data.data.waybillItemDos
          })
        } else {
          this.$message({
            type: "info",
            message: "修改操作只能选择初始状态的单据进行操作",
          })
        }
      }
    },
    nullToStr (data) {
      for (const x in data) {
        if (data[x] === null) {
          // 如果是null 把直接内容转为 ''
          data[x] = ""
        } else {
          if (Array.isArray(data[x])) {
            // 是数组遍历数组 递归继续处理
            data[x] = data[x].map((z) => {
              return nullToStr(z)
            })
          }
          if (typeof data[x] === "object") {
            // 是json 递归继续处理
            data[x] = nullToStr(data[x])
          }
        }
      }
      return data
    },
    // 订单发布
    waybillManagementRelease () {
      this.selectionLineChangeHandle()
      const waybilldata = []
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "发布操作必须选中一条数据",
        })
      } else {
        for (let i = 0; i < this.merge.length; i++) {
          waybilldata.push(this.merge[i].id)
        }
        axios({
          method: "post",
          url: `${this.$apiPath}/api/urban/waybill/publish`,
          data: waybilldata,
        }).then((res) => {
          if (res.data.success == true) {
            this.$message({
              message: "发布成功",
              type: "success",
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, "提示", {
              confirmButtonText: "确定",
            })
          }
        })
      }
    },
    // 发布回退
    waybillPublishFallback () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "发布回退操作必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "发布回退操作只能选择单条数据进行操作",
        })
      } else {
        if (this.merge[0].transStatus == 0) {
          this.$message({
            type: "info",
            message: "该订单已是初始状态，不需要发布回退",
          })
        } else {
          this.$confirm("此操作将对单据进行发布回退, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
              .then(() => {
                const waybilldata = new URLSearchParams()
                waybilldata.append("orderNo", this.merge[0].orderNo)
                axios({
                  method: "post",
                  url: `${this.$apiPath}/api/urban/waybill/publishBack`,
                  data: waybilldata,
                }).then((res) => {
                  if (res.data.success == true) {
                    this.$message({
                      message: "订单发布回退成功",
                      type: "success",
                    })
                    this.waybillManagementSearch()
                  } else {
                    this.$alert(res.data.msg, "提示", {
                      confirmButtonText: "确定",
                    })
                  }
                })
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消撤单",
                })
              })
        }
      }
    },
    // 送达
    waybillManagementService () {
      this.selectionLineChangeHandle()
      const preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "送达操作必须选中一条数据",
        })
      } else {
        for (let i = 0; i < this.merge.length; i++) {
          if (this.merge[i].transStatus == 6) {
            preOrderNos.push(this.merge[i].orderNo)
          } else {
            this.$message({
              type: "info",
              message: "您所勾选的订单存在不是派送中的单据，请重新勾选",
            })
            return false
          }
        }
        axios({
          method: "post",
          url: `${this.$apiPath}/api/urban/waybill/deliveryWayBill`,
          data: preOrderNos,
        }).then((res) => {
          if (res.data.success == true) {
            this.$message({
              message: "送达成功",
              type: "success",
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, "提示", {
              confirmButtonText: "确定",
            })
          }
        })
      }
    },
    // 撤单
    waybillManagementCancel () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "撤单操作必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "撤单操作只能选择单条数据进行操作",
        })
      } else {
        this.$confirm("此操作将对单据进行撤单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              const waybilldata = new URLSearchParams()
              waybilldata.append("orderNo", this.merge[0].orderNo)
              axios({
                method: "post",
                url: `${this.$apiPath}/api/urban/waybill/revokeWayBill`,
                data: waybilldata,
              }).then((res) => {
                if (res.data.success == true) {
                  this.$message({
                    message: "撤单成功",
                    type: "success",
                  })
                  this.waybillManagementSearch()
                } else {
                  this.$alert(res.data.msg, "提示", {
                    confirmButtonText: "确定",
                  })
                }
              })
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消撤单",
              })
            })
      }
    },
    // 揽收
    waybillManagementCollect () {
      this.selectionLineChangeHandle()
      const preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "合单操作必须选中一条数据",
        })
      } else {
        for (let i = 0; i < this.merge.length; i++) {
          preOrderNos.push(this.merge[i].orderNo)
        }
        axios({
          method: "post",
          url: `${this.$apiPath}/api/urban/waybill/receive`,
          data: preOrderNos,
        }).then((res) => {
          if (res.data.success == true) {
            this.$message({
              message: "揽收成功",
              type: "success",
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, "提示", {
              confirmButtonText: "确定",
            })
          }
        })
      }
    },
    // 揽收回退
    waybillManagementCollectBack () {
      this.selectionLineChangeHandle()
      const preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "揽收回退操作必须选中一条数据",
        })
      } else {
        for (let i = 0; i < this.merge.length; i++) {
          preOrderNos.push(this.merge[i].orderNo)
        }
        axios({
          method: "post",
          url: `${this.$apiPath}/api/urban/waybill/receive/cancel`,
          data: preOrderNos,
        }).then((res) => {
          if (res.data.success == true) {
            this.$message({
              message: "揽收回退成功",
              type: "success",
            })
            this.waybillManagementSearch()
          } else {
            this.$alert(res.data.msg, "提示", {
              confirmButtonText: "确定",
            })
          }
        })
      }
    },
    savewaybill () {
      this.$refs.waybillDoForm.validate((valid) => {
        if (valid) {
          // if(this.BuyerList.province == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }else if(this.BuyerList.city == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }
          //
          if (this.SellerList.province == "") {
            this.$message.error("卖家省市区信息填写不完整")
            return false
          } else if (this.SellerList.city == "") {
            this.$message.error("卖家省市区信息填写不完整")
            return false
          }

          if (
              this.waybillDoForm.pkgQty <= 0 ||
              this.waybillDoForm.weight <= 0 ||
              this.waybillDoForm.volume <= 0
          ) {
            this.$message({
              message: "总件数、总重量、总体积必须全部大于0的值",
              type: "warning",
            })
          } else {
            // 运单新增，保存完直接关闭
            const saveParmas = {}
            saveParmas.waybillDo = this.waybillDoForm
            saveParmas.waybillItemDos = this.materialTableData
            if (this.dialog_waybillMan_Title_Flag == "新建运单") {
              // 表示新增
              const addRowFlag = this.materialNameTleBlur()
              if (addRowFlag == false) {
              } else {
                axios({
                  method: "post",
                  url: `${this.$apiPath}/api/urban/waybill/createWayBill`,
                  data: saveParmas,
                }).then((res) => {
                  if (res.data.success == true) {
                    this.$message({
                      message: res.data.msg + "，运单号:" + res.data.data,
                      type: "success",
                    })
                    this.handleClose_waybilladd()
                    this.waybillManagementSearch()
                  } else {
                    this.$alert(res.data.msg, "提示", {
                      confirmButtonText: "确定",
                    })
                  }
                })
              }
            } else if (this.dialog_waybillMan_Title_Flag == "修改运单") {
              const addRowFlag = this.materialNameTleBlur()
              if (addRowFlag == false) {
              } else {
                // 表示修改
                axios({
                  method: "post",
                  url: `${this.$apiPath}/api/urban/waybill/updateWayBill`,
                  data: saveParmas,
                }).then((res) => {
                  if (res.data.success == true) {
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    })
                    this.handleClose_waybilladd()
                    this.waybillManagementSearch()
                  } else {
                    this.$alert(res.data.msg, "提示", {
                      confirmButtonText: "确定",
                    })
                  }
                })
              }
            }
          }
        } else {
          this.$message.error("请检查您输入的数据,请核对后重试!")
        }
      })
    },
    saveAndAdd () {
      this.$refs.waybillDoForm.validate((valid) => {
        if (valid) {
          // if(this.BuyerList.province == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }else if(this.BuyerList.city == ''){
          //     this.$message.error('买家省市区信息填写不完整');
          //     return false
          // }

          if (this.SellerList.province == "") {
            this.$message.error("卖家省市区信息填写不完整")
            return false
          } else if (this.SellerList.city == "") {
            this.$message.error("卖家省市区信息填写不完整")
            return false
          }

          if (
              this.waybillDoForm.pkgQty <= 0 ||
              this.waybillDoForm.weight <= 0 ||
              this.waybillDoForm.volume <= 0
          ) {
            this.$message({
              message: "总件数、总重量、总体积必须全部大于0的值",
              type: "warning",
            })
          } else {
            const saveParmas = {}
            saveParmas.waybillDo = this.waybillDoForm
            saveParmas.waybillItemDos = this.materialTableData
            // 表示新增,且不关闭窗口，并且保留发货方信息
            const addRowFlag = this.materialNameTleBlur()
            if (addRowFlag == false) {
            } else {
              axios({
                method: "post",
                url: `${this.$apiPath}/api/urban/waybill/createWayBill`,
                data: saveParmas,
              }).then((res) => {
                if (res.data.success == true) {
                  this.$message({
                    message: res.data.msg + "，运单号:" + res.data.data,
                    type: "success",
                  })
                  // 除发货方/和收货方信息之外全部清空
                  // this.waybillDoForm.vendeeName = '';
                  // this.waybillDoForm.vendeeAddress = '';
                  // this.waybillDoForm.vendeeLinkman = '';
                  // this.waybillDoForm.vendeePhone = '';
                  // this.waybillDoForm.vendeeMobile = '';
                  // this.waybillDoForm.requiredDeliveryTime = '';
                  // this.waybillDoForm.vendeeLine = '';
                  this.waybillDoForm.pkgQty = ""
                  this.waybillDoForm.weight = ""
                  this.waybillDoForm.volume = ""
                  this.waybillDoForm.wunit = ""
                  this.waybillDoForm.vunit = ""
                  this.materialTableData = []
                } else {
                  this.$alert(res.data.msg, "提示", {
                    confirmButtonText: "确定",
                  })
                }
              })
            }
          }
        } else {
          this.$message.error("请检查您输入的数据,请核对后重试!")
        }
      })
    },

    // 清除收货方信息
    header_clear () {
      this.waybillDoForm.vendeeName = ""
      this.waybillDoForm.vendeeAddress = ""
      this.waybillDoForm.vendeeLinkman = ""
      this.waybillDoForm.vendeePhone = ""
      this.waybillDoForm.vendeeMobile = ""
      this.waybillDoForm.requiredDeliveryTime = ""
      this.waybillDoForm.vendeeLine = ""
      this.BuyerList.province = ""
      this.BuyerList.city = ""
      this.BuyerList.area = ""
    },
    // 清除发货方信息
    header_clearone () {
      this.waybillDoForm.consignLinkman = ""
      this.waybillDoForm.consignMobile = ""
      this.waybillDoForm.consignPhone = ""
      this.waybillDoForm.chintBusiness = ""
      this.waybillDoForm.consignName = ""
      this.waybillDoForm.replyDeliveryTime = ""
      this.waybillDoForm.outGoing = ""
      this.waybillDoForm.consignAddress = ""
      this.SellerList.province = ""
      this.SellerList.city = ""
      this.SellerList.area = ""
    },
    // 上传回单
    waybillManagementReceiptUpload () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "回单上传操作必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "回单上传操作只能选择单条数据进行操作",
        })
      } else {
        if (this.merge[0].transStatus == 6 || this.merge[0].transStatus == 8) {
          this.saveReceipt.orderNo = this.merge[0].orderNo
          this.dialog_waybillManagementReceiptUpload = true
        } else {
          this.$message({
            type: "info",
            message: "回单上传操作只能选择派送中或已送达的单据进行操作",
          })
        }
      }
    },
    // 导出
    waybillManagementExport () {
      // 导出去掉勾选的数据，按照高级查询的数据给后台，后台查出多少数据导出多少数据，去掉pageSize、pageNo参数，因为前端不做分页显示，其余参数与高级查询一致
      let createTimeBegin
      let createTimeEnd
      if (
          this.dataForms.createTimeBegin == "" ||
          this.dataForms.createTimeBegin == null
      ) {
        createTimeBegin = ""
      } else {
        createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format(
            "YYYY-MM-DD"
        )
      }
      if (
          this.dataForms.createTimeEnd == "" ||
          this.dataForms.createTimeEnd == null
      ) {
        createTimeEnd = ""
      } else {
        createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format(
            "YYYY-MM-DD"
        )
      }
      const data = new URLSearchParams()
      data.append("orderNo", this.dataForms.orderNo)
      data.append("vendeeLine", this.dataForms.vendeeLine)
      data.append("batchNo", this.dataForms.batchNo)
      data.append("status", this.dataForms.status)
      data.append("transStatus", this.dataForms.transStatus)
      data.append("companyName", this.dataForms.companyName)
      data.append("vendeeName", this.dataForms.vendeeName)
      data.append("consignName", this.dataForms.consignName)
      data.append("carrierId", this.dataForms.carrierId)
      data.append("driver", this.dataForms.driver)
      data.append("truckNo", this.dataForms.truckNo)
      data.append("createTimeBegin", createTimeBegin)
      data.append("createTimeEnd", createTimeEnd)
      data.append("proviceAdcode", this.addList.province)
      data.append("cityAdcode", this.addList.city)
      data.append("regionAdcode", this.addList.area)
      data.append("chintBusiness", this.dataForms.chintBusiness)
      data.append("outGoing", this.dataForms.outGoing)

      const loading = this.$loading({
        lock: true,
        text: "导出中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      })
      // console.log(data);
      axios({
        method: "post",
        url: `${this.$apiPath}/api/urban/waybill/checkExportCount`,
        data: data,
      }).then((res) => {
        if (res.data.success == true) {
          axios({
            method: "post",
            url: `${this.$apiPath}/api/urban/waybill/exportWayBill`,
            data: data,
            responseType: "arraybuffer",
          })
              .then((res) => {
                let url = window.URL.createObjectURL(
                    new Blob([res.data], { type: ".xlsx" })
                )
                console.log(res.data)
                const a = document.createElement("a")
                a.style.display = "none"
                a.href = url
                a.setAttribute("download", `运单报表.xlsx`)
                document.body.appendChild(a)
                a.click()
                url = window.URL.revokeObjectURL(url)
                document.body.removeChild(a)
                loading.close()
              })
              .catch((error) => {
                this.$message.error("导出失败")
                loading.close()
              })
        } else {
          this.$alert(res.data.msg, "提示", {
            confirmButtonText: "确定",
          })
          loading.close()
        }
      })
    },

    // 托运单打印
    consignment () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "托运单打印必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "托运单打印只能选择单条数据进行操作",
        })
      } else {
        const routeUrlHref =
            `${this.$apiPath}/api/urban/reports/export/transportInfoPdf?orderNo=` +
            this.merge[0].orderNo
        window.open(routeUrlHref, "_blank")
      }
    },
    // 托运单五联纸打印
    immovable () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "托运单打印必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "托运单打印只能选择单条数据进行操作",
        })
      } else {
        const routeUrlHref =
            `${this.$apiPath}/api/urban/reports/export/transportInfoGufenPdf?orderNo=` +
            this.merge[0].orderNo
        window.open(routeUrlHref, "_blank")
      }
    },
    isAllEqual (array) {
      if (array.length > 0) {
        return !array.some(function (value, index) {
          return value !== array[0]
        })
      } else {
        return true
      }
    },
    // 交接单打印
    handover () {
      this.selectionLineChangeHandle()

      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "交接单打印必须选中一条数据",
        })
      } else {
        let orderNos = ""
        const carriersIdlist = []
        for (let i = 0; i < this.merge.length; i++) {
          if (this.merge[i].assignStatus == 0) {
            this.$message({
              message: "您勾选的单据中有未派车的单据，不能打印交接单！",
              type: "info",
            })
            return false
          } else {
            carriersIdlist.push(this.merge[i].carriersId)
            orderNos += this.merge[i].orderNo + ","
          }
        }
        if (this.isAllEqual(carriersIdlist) == false) {
          this.$message({
            message:
                "您勾选的多条单据中不是同一个承运商，不能打印交接单，请检查！",
            type: "info",
          })
          return false
        } else {
          if (orderNos.length > 0) {
            orderNos = orderNos.substr(0, orderNos.length - 1)
          }
          const routeUrlHref =
              `${this.$apiPath}/api/urban/reports/export/handoverPapersPdf?orderNos=` +
              orderNos
          window.open(routeUrlHref, "_blank")
        }
      }
    },

    receiptConfirmation () {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: "info",
          message: "回单确认操作必须选中一条数据",
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: "info",
          message: "回单确认操作只能选择单条数据进行操作",
        })
      } else {
        if (
            this.merge[0].orderReceiptUrl == "" ||
            this.merge[0].orderReceiptUrl == null ||
            this.merge[0].orderReceiptUrl == "null"
        ) {
          this.$message({
            type: "info",
            message: "回单确认操作只能选取已有回单附件的单据进行操作",
          })
        } else if (this.merge[0].receiptStatus == 3) {
          this.$message({
            type: "info",
            message: "该回单已确认，不允许继续操作！",
          })
        } else {
          this.dialogVisible_orderNo = "运单号：" + this.merge[0].orderNo
          this.dialogImageUrl_via =
              `${this.$imageiPPath}` + this.merge[0].orderReceiptUrl
          this.dialogImageUrl_via_srcList.push(
              `${this.$imageiPPath}` + this.merge[0].orderReceiptUrl
          )
          this.dialogVisible_imgUrl_flag = true
          this.dialogVisible_imgUrl = true
        }
      }
    },
    // 重置按钮
    resetFrom () {
      this.$refs.dataForms.resetFields()
      this.value1 = [];
      this.value2 = [];
    },
    // 回单通过
    SavereceiptPassed () {
      const uploadData = {}
      uploadData.orderNo = this.merge[0].orderNo
      uploadData.receiptStatus = "3"
      axios({
        method: "post",
        url: `${this.$apiPath}/api/urban/waybill/confirmReceipt`,
        data: uploadData,
      }).then((res) => {
        if (res.data.success == true) {
          this.$message({
            message: "回单已确认通过",
            type: "success",
          })
          this.handleCloseImgDialog()
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, "提示", {
            confirmButtonText: "确定",
          })
        }
      })
    },
    // 回单驳回
    Savereceiptreject () {
      const uploadData = {}
      uploadData.orderNo = this.merge[0].orderNo
      uploadData.receiptStatus = "2"
      axios({
        method: "post",
        url: `${this.$apiPath}/api/urban/waybill/confirmReceipt`,
        data: uploadData,
      }).then((res) => {
        if (res.data.success == true) {
          this.$message({
            message: "回单已驳回",
            type: "success",
          })
          this.handleCloseImgDialog()
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, "提示", {
            confirmButtonText: "确定",
          })
        }
      })
    },
    handleClose (done) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.timer = setTimeout(() => {
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false
        }, 400)
        this.waybillManagementSearch_Multiple()
        // 动画关闭需要一定的时间
        this.dialog = false
      }, 2000)
    },
    cancelForm () {
      this.loading = false
      this.dialog = false
      this.addList.province = ""
      this.addList.city = ""
      this.addList.area = ""
      clearTimeout(this.timer)
    },
    handleDelete (val) {},
    // checkbox的触发接收
    handleSelectionChange (val) {},
    // 双击事件的行触发
    clickTable (val) {
      this.waybillDoForm.consignId = val.row.carriersId
      this.waybillDoForm.consignName = val.row.carriersName
      this.dialog_comm_carriers = false
    },
    // button的触发
    handleEdit (val) {},
    handleSizeChange (val) {},
    handleClose_waybillManagementReceiptUpload () {
      this.dialog_waybillManagementReceiptUpload = false
      this.imgflagmodel = false
      this.imgflagdel = false
      this.imgflag = true
      this.srcList.length = 0
    },
    save_Upload () {
      const uploadData = new URLSearchParams()
      uploadData.append("orderNo", this.saveReceipt.orderNo)
      uploadData.append("orderReceiptUrl", this.saveReceipt.orderReceiptUrl)
      axios({
        method: "post",
        url: `${this.$apiPath}/api/urban/waybill/saveReceipt`,
        data: uploadData,
      }).then((res) => {
        if (res.data.success == true) {
          this.$message({
            message: "上传成功",
            type: "success",
          })
          this.handleClose_waybillManagementReceiptUpload()
          this.waybillManagementSearch()
        } else {
          this.$alert(res.data.msg, "提示", {
            confirmButtonText: "确定",
          })
        }
      })
    },
  },
}
</script>
<style>
.tableMain .ces-table-page {
  height: 100%;
}
.tableMain .ces-table {
  height: 100%;

}
.tableMain .el-table{
  height: 80% !important;
}
.tableMain .el-table th {
  padding: 0;
  height: 35px;
}
.tableMain .el-table td {
  padding: 0;
  height: 35px;
}
.waybill_add .ces-table {
  height: 200px;
}
.waybill_add .el-table th {
  padding: 0;
  height: 30px;
}
.waybill_add .el-table td {
  padding: 0;
  height: 30px;
}
.materialTableData .ces-table {
  height: 200px;
}
.materialTableData .el-table th {
  padding: 0;
  height: 30px;
}
.materialTableData .el-table td {
  padding: 0;
  height: 30px;
}
.materialTableData .el-input__inner {
  height: 30px;
}
</style>
<style scoped>
.boxbutton {
  width: 100%;
  height: 40px;
}
.tableMain {
  width: 100%;
  height: 88%;
}
.el-table{
  height: 80% !important;
}
</style>
